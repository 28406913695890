<template>
  <div class="bankWallet">
    <h2>{{ $t("userText.bindDigitalAddress") }}</h2>
    <div class="mainContent">
      <p>{{ $t("userText.digitalAddress") }}</p>
      <div class="bankContainer">
        <div class="addressBox">
          <div class="addressItem" v-for="(item, index) in list" :key="item.id">
            <div class="addressTop">
              <div class="nameBox">
                <span>{{ item.bank_name }}</span>
                <span>{{ $t("userText.bindText") }}</span>
              </div>
              <el-button
                size="mini"
                type="danger"
                plain
                @click="relieve(item)"
                >{{ $t("userText.relieveText") }}</el-button
              >
            </div>
            <p>{{ item.bank_account }}</p>
          </div>
        </div>
        <div class="btnBox">
          <el-button @click="handOpen" style="width: 50%" type="primary">{{
            $t("userText.addBankText")
          }}</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('tips.addText')"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item prop="bank_name" :label="$t('market.currency')">
          <el-select
            style="width: 100%"
            v-model="form.bank_name"
            :placeholder="$t('tips.pleaseSelect')"
          >
            <el-option
              v-for="item in bankTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bank_account" :label="$t('userText.bankAddress')">
          <el-input
            v-model="form.bank_account"
            :placeholder="$t('tips.pleaseEnter')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{
          $t("tips.cancelText")
        }}</el-button>
        <el-button size="small" type="primary" @click="submit">{{
          $t("tips.submitText")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  computed,
  onMounted,
  ref,
  getCurrentInstance,
  watch,
  reactive,
} from "vue";
import { Message, MessageBox } from "element-ui";
import { getcash_info, cash_save, cash_del } from "@/api/user";
import { useOption } from "@/hooks/useOption";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
const { bankTypeList } = useOption();
const { proxy } = getCurrentInstance();
const list = ref([]);
const dialogVisible = ref(false);
const form = reactive({
  bank_name: null, // 类型
  bank_account: "",
});
const rules = ref({
  bank_name: [
    {
      required: true,
      message: proxy.$t("tips.pleaseSelect"),
      trigger: "change",
    },
  ],
  bank_account: [
    {
      required: true,
      message: proxy.$t("tips.pleaseEnter"),
      trigger: "blur",
    },
  ],
});
const ruleForm = ref(null);
const handOpen = () => {
  form.bank_name = null;
  form.bank_account = "";
  dialogVisible.value = true;
};
const submit = () => {
  ruleForm.value.validate(async (valid) => {
    if (valid) {
      const { message, type } = await cash_save({ ...form, lang: lang.value });
      if (type == "ok") {
        Message({
          message: proxy.$t(message),
          type: "success",
        });
        dialogVisible.value = false;
        getData();
      } else {
        Message({
          message: proxy.$t(message),
          type: "error",
        });
      }
    }
  });
};
// 解绑
const relieve = async (item) => {
  MessageBox.confirm(proxy.$t("tips.sureRelieveText"), proxy.$t("tips.tip"), {
    showClose: true,
    confirmButtonText: proxy.$t("tips.sureText"),
    cancelButtonText: proxy.$t("tips.cancelText"),
    type: "warning",
  }).then(async () => {
    const { message, type } = await cash_del({
      id: item.id,
      lang: lang.value,
    });
    if (type == "ok") {
      Message({
        message: proxy.$t(message),
        type: "success",
      });
      getData();
    } else {
      Message({
        message: proxy.$t(message),
        type: "error",
      });
    }
  });
};
// 获取钱包列表
const getData = async () => {
  const { message } = await getcash_info({ lang: lang.value });
  list.value = message;
};
// 获取数据
onMounted(() => {
  getData();
});
</script>

<style lang="scss" scoped>
.bankWallet {
  width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin: 30px;
  }
  .mainContent {
    display: flex;
    background: $section-color;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 10px;
    width: 100%;
    & > p {
      font-size: 20px;
      font-weight: 600;
    }
    .bankContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 10px;
    }
    .addressBox {
      width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .addressItem {
        display: flex;
        flex-direction: column;
        width: 31%;
        margin: 20px 10px;
        background-color: $theme-color;
        border: 1px solid $market-list-hover;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
        justify-content: space-between;
        height: 80px;
        .addressTop {
          display: flex;
          justify-content: space-between;
          .nameBox {
            display: flex;
            align-items: center;
            span:nth-child(1) {
              font-size: 18px;
            }
            span:nth-child(2) {
              font-size: 12px;
              background-color: #5670f6;
              color: #fff;
              border-radius: 5px;
              padding: 2px 5px;
              margin-left: 5px;
              margin-right: 5px;
            }
          }
        }
        p {
          margin: 0;
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      margin: 50px 20px 20px;
    }
  }
}
</style>
